<template>
  <div class="page">
    <nav-top :index="1"></nav-top>
    <div class="crumbs-info">
      <div class="crumbs-search">
        <div class="crumbs">
          <div class="item">
            <router-link to="/">首页</router-link>
            <span class="right-arrows"></span>
          </div>
          <div class="item">
            <router-link to="/look">看点</router-link>
            <span class="right-arrows"></span>
          </div>
          <div class="item active">
            <router-link to="#">文章详情</router-link>
          </div>
        </div>
        <div class="search">
<!--          <div class="search-text">-->
<!--            <div class="text">-->
<!--              <input type="text" placeholder="请输入关键词">-->
<!--            </div>-->
<!--            <div class="btn">-->
<!--              <img src="/static/images/fdj_ico2.png" alt="">-->
<!--            </div>-->
<!--          </div>-->
          <div class="report-btn">
            <span>快速报备</span>
          </div>
        </div>
      </div>
    </div>
    <div class="hotspot-detail-wrapper">
      <div class="detail-wrapper">
        <div class="title">
          <div class="left">
            <span>文章详情</span>
            <span>/</span>
            <span>ARTICLE DETAILS</span>
          </div>
          <div class="right">
            <div class="back-wrapper" @click="$router.push({path:'/look'})">
              <span>返回看点列表</span>
              <el-image src="/static/images/back-news-list.png"></el-image>
            </div>
          </div>
        </div>

        <div class="article-title">{{ article_data?.title }}</div>
        <div class="article-content" v-html="article_data?.content"></div>
        <div class="article-other">
          <div class="prev" v-show="article_data?.prev" @click="handleArticleOtherItemClick(article_data?.prev?.id)">
            <el-image src="/static/images/article-prev.png" fit="cover"></el-image>
            <span>上一篇：{{ article_data?.prev?.title }}</span>
          </div>
          <div class="next" v-show="article_data?.next" @click="handleArticleOtherItemClick(article_data?.next?.id)">
            <el-image src="/static/images/article-next.png" fit="cover"></el-image>
            <span>下一篇：{{ article_data?.next?.title }}</span>
          </div>
        </div>
      </div>

      <div class="hot-wrapper">
        <div class="hot-title">
          <div class="color-block"></div>
          <div class="text">热门看点</div>
        </div>
        <div class="hot-item-wrapper">
          <div class="hot-item" v-for="item in article_data.hot" :key="item" @click="handleArticleOtherItemClick(item.id)">
            <el-image :src="item.image" mode="aspectFill"></el-image>
            <div class="hot-item-title">{{item.title}}</div>
<!--            <div class="hot-item-desc">{{item.desc}}</div>-->
          </div>
        </div>
      </div>
    </div>
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import NavTop from '../../components/NavTop/NavTop'
import FooterBar from '../../components/FooterBar/FooterBar'

export default {
  name: 'lookDetails',
  components: {FooterBar, NavTop},
  data() {
    return {
      article_id: null,
      article_data: null,
      detail: ''
    }
  },
  created() {
    this.article_id = this.$route.query.id
    this.fetchData()
  },
  activated() {
    console.log('activated')
  },
  methods: {
    async fetchData() {
      let res = await this.http({
        url: '/api/article/detail',
        method: 'GET',
        params: {
          id: this.article_id,
        }
      })
      // this.$set('article_data')
      this.article_data = res.data
      console.log(res)
    },
    handleArticleOtherItemClick(id) {
      this.$router.push({
        path: '/lookDetails',
        query: {
          id: id,
        }
      })
    }
  },
  watch: {
    $route(to) {
      if (to.query.id !== this.article_id) {
        this.article_id = to.query.id
        this.fetchData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "lookDetails";
</style>
